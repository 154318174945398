.youtube-player {
  height: 210px;
}

.youtube-player-error {
  background-color: $accent-dark;
  padding: $spacer-2-value $spacer-1-value;
}

@include media-breakpoint-up(md) {
  .youtube-player {
    height: 432px;
  }
}

@include media-breakpoint-up(lg) {
  .youtube-player {
    height: 562px;
  }
}

@include media-breakpoint-up(xl) {
  .youtube-player {
    height: 810px;
  }
}
